<template>
    <header class="page-title">
        <h1><i class="bi bi-people-fill"></i> 顧客一覧</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <form @submit.prevent="search()" class="row align-items-end">
            <div class="col-6">
                <label class="form-label">顧客名</label>
                <form-input v-model="company_name"></form-input>
            </div>
            <div class="col-12">
                <button type="submit" :disabled="loading" class="btn btn-primary">検索</button>
            </div>
        </form>
    </section>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <template v-if="customers.length">
                <div class="mx-1 mb-3 px-3 d-flex align-items-center">該当 : {{ customers_total }} 件</div>
                <table class="table table-sm w-100">
                    <thead>
                        <tr class="table-dark">
                            <th v-if="$store.getters['auth/canEditProject']"></th>
                            <th class="text-center w-mm">会社名</th>
                            <th class="text-center w-mm">本社住所</th>
                            <th class="text-center w-mm">代表者名</th>
                            <th class="text-center w-mm">業種</th>
                            <th class="text-center">案件数</th>
                            <th v-if="$store.getters['auth/canEditProject']"></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="customer in customers" :key="customer.customer_id">
                            <td v-if="$store.getters['auth/canEditProject']"><label class="checkbox-border-none text-center"><input type="checkbox" class="form-check-input" v-model="customer_merge.merged_customer_ids" :value="customer.customer_id"></label></td>
                            <td class="align-middle">{{ customer.company_name }}</td>
                            <td class="align-middle">{{ customer.address }}</td>
                            <td class="align-middle text-center">{{ customer.representative_name }}</td>
                            <td class="align-middle text-center">{{ customer.industry_label }}</td>
                            <td class="text-center">
                                <button @click="openProjectListModal(customer)" type="button" class="btn btn-link" data-bs-toggle="modal" :data-bs-target="`#projectListModal`">{{ customer.projects.length }} 件</button>
                            </td>
                            <td v-if="$store.getters['auth/canEditProject']" class="text-center text-nowrap">
                                <button @click="removeReady(customer)" class="me-3 btn btn-outline-danger">削除</button>
                                <button @click="mergeReady(customer.customer_id)" class="btn btn-outline-danger">チェックした顧客を統合</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- ページネーション -->
                <paginator :meta="paginator" @move="search"></paginator>
            </template>
            <template v-else>
                <p class="alert alert-secondary">※該当の顧客が存在しません</p>
            </template>

            <!-- 各会社クリック時OPENモーダル -->
            <!--
                bootstrapのモーダル機能を利用している
                showクラスが追加されると開く
                class="modal fade show"
            -->
            <div class="modal fade" :id="`projectListModal`" tabindex="-1" :aria-labelledby="`projectListModal`" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" :id="`activityModalLabel`">案件一覧 - {{ modal_customer ? modal_customer.company_name : '' }}</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body mb-5">
                            <div v-if="modal_projects_list.length">
                                <table class="table table-sm">
                                    <thead>
                                        <tr class="table-dark table-head">
                                            <th></th>
                                            <th class="text-center w-ms">反響日</th>
                                            <th class="text-center w-ms">客先担当者</th>
                                            <th class="text-center w-mm">事業部</th>
                                            <th class="text-center w-ss">追客担当</th>
                                            <th class="text-center w-ss">ステータス</th>
                                            <th class="text-center w-ss">評価</th>
                                            <th class="text-center w-mm">規格</th>
                                            <th class="text-center">その他規格・備考</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="project in modal_projects_list" :key="project.project_id">
                                            <td @click.stop>
                                                <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i></router-link>
                                            </td>
                                            <td class="align-middle text-start px-3">{{ $helper.ymd(project.contact_date) }}</td>
                                            <td class="align-middle text-start px-3">{{ project.primary_contact ? project.primary_contact.contact_name : '' }}</td>
                                            <td>{{ project.department ? project.department.department_name : '' }}</td>
                                            <td>{{ project.follow_user ? project.follow_user.user_name : '' }}</td>
                                            <td>{{ project.status_label }}</td>
                                            <td>{{ project.rank_label }}</td>
                                            <td>{{ project.standards_label }}</td>
                                            <td class="fs-7">{{ project.standard_note }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else>
                                <p class="alert alert-secondary">{{ modal_loading ? '検索中です' : '※案件が存在しません' }}</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary mb-0" data-bs-dismiss="modal">閉じる</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

    <confirm-dialog ref="confirmMerge" @ok="customerMerge()">
        <p>統合してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Customer from '@/models/entities/customer';
import Project from '@/models/entities/project';
import Paginator from '@/components/tools/Paginator';
import FormInput from '@/components/forms/FormInput';
import ConfirmDialog from '@/components/tools/ConfirmDialog';

export default {
    name: 'Customer',
    components: {
        InlineLoader,
        Paginator,
        FormInput,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: false,
            modal_loading: false,

            // 検索用
            company_name: null,
            page: null,

            // 表示用顧客一覧
            customers: [],
            customers_total: null,
            // ページネーション
            paginator: {},

            // 削除用
            customer_to_remove: null,

            // 顧客統合
            customer_merge: {
                merging_customer_id: null,
                merged_customer_ids: [],
            },

            modal_projects_list: [],
            modal_customer: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        // 検索
        search(page = 1) { // ページネーションクリック時pageが渡される
            this.loading = true;
            this.page = page;

            this.customers.splice(0);

            this.$http.get('/customer', {
                params: {
                    company_name: this.company_name,
                    page: this.page,
                }
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.customers.push(new Customer(row))
                }

                // ページネーション
                this.paginator = response.data.meta;
                this.customers_total = response.data.meta.total;
            })
            .finally(() => {
                this.loading = false;
            });
        },

        // 削除準備
        removeReady(customer) {
            // 紐づく案件がある場合削除不可
            if (customer.projects.length) {
                this.showErrorMessage('紐づく案件が存在するため削除できません');
                return;
            }

            this.customer_to_remove = customer;
            this.$refs.confirmRemove.show();
        },
        // 削除実行
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/customer/${this.customer_to_remove.customer_id}`)
            .then(() => {
                // リストから削除
                const removed_index = this.customers.findIndex(customer => customer.customer_id === this.customer_to_remove.customer_id);
                this.customers.splice(removed_index, 1);
                this.customer_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },

        // 統合準備
        mergeReady(customer_id) {
            // チェックされた顧客がなければreturn
            if (this.customer_merge.merged_customer_ids.length === 0) {
                this.showErrorMessage('統合する顧客をチェックしてください');
                return;
            }

            this.customer_merge.merging_customer_id = customer_id;
            this.$refs.confirmMerge.show();
        },
        // 統合処理
        customerMerge() {
            // 統合する側のcustomer_idが統合される側のcustomer_idリストの中にあれば削除 = 自身は取り除く
            if (this.customer_merge.merged_customer_ids.includes(this.customer_merge.merging_customer_id)) {
                // merged_customer_idsの中から削除
                const remove_index = this.customer_merge.merged_customer_ids.findIndex(merged_customer_id => merged_customer_id === this.customer_merge.merging_customer_id);
                this.customer_merge.merged_customer_ids.splice(remove_index, 1);
            }

            // 自身を取り除いた結果merged_customer_idsが空になったら統合処理しないてゆうかできない
            if (this.customer_merge.merged_customer_ids.length === 0) {
                this.showErrorMessage('自身を統合することはできません');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/customer/merge', this.customer_merge)
            .then(() => {
                this.showMessage('顧客を統合しました');
                this.search(this.page);
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },

        //指定会社の案件一覧モーダルOPEN
        openProjectListModal(customer) {
            this.modal_loading = true;
            // リストをリセット
            this.modal_projects_list.splice(0);
            this.modal_customer = customer;
            this.startScreenLoading();

            //案件一覧取得
            this.$http.get(`/project/customer/${customer.customer_id}`)
            .then((response) => {
                for(let row of response.data) {
                    this.modal_projects_list.push(new Project(row));
                }
            })
            .finally(() => {
                this.modal_loading = false;
                this.endScreenLoading();
            })
        },
    }
}
</script>

<style scoped>
    .checkbox-border-none {
        border: none;
    }
    .w-mm {
        width: 16%;
    }
    .w-ms {
        width: 13%;
    }
    .w-ss {
        width: 10%;
    }
    .fs-7 {font-size: .85rem;}
</style>
